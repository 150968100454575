import './formTitleLine.css';
import Title from '../title/title';
import closeForm from '../../img/icons/ic_close-form.svg';

function FormTitleLine({setIsOpen}) {
    return (
        <div className='form__title-line'>
            <Title whatSection={'form'}/>
            <img src={closeForm} alt='close' onClick={() => {
                setIsOpen(false)
            }}/>
        </div>
    );
}

export default FormTitleLine;
