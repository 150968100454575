import './logo.css';
import logo from '../../img/icons/ic_dodo.svg';
import logo_min from '../../img/icons/ic_dodo-min.svg';

function Logo({whichLine}) {
    return (
        whichLine === 'banner' ?
            <img className='logo' src={logo} alt={'dodo_pizza'}/>
            :
            <img className='logo-min' src={logo_min} alt={'dodo_pizza'}/>
    );
}

export default Logo;
