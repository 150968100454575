import { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './formDatepicker.css';

function FormDatepicker({placeholder, name}) {

    const [startDate, setStartDate] = useState();
    const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className='datepicker-input'
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            placeholder={placeholder}
            name={name}
            autoComplete='off'
            required
        ></input>
    ));

    return (
        <DatePicker
            selected={startDate}
            dateFormat='dd-MM-yyyy'
            minDate={new Date()}
            onChange={(date) => setStartDate(date)}
            customInput={<ExampleCustomInput />}
            shouldCloseOnSelect={true}
            dayClassName={() => 'datepicker-day-class'}
            popperClassName='datepicker-class'
        />
    );
}

export default FormDatepicker;
