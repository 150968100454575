import './qrCode.css';
import qr from '../../img/icons/qr-code.svg';

function QrCode() {
    return (
        <div className={'footer__qr-code qr-code'}>
            <img src={qr} alt={'qr'}/>
            <span>Pobierz<br/> aplikację<br/> Dodo Pizza</span>
        </div>
    );
}

export default QrCode;
