import './inputLabelSpan.css';

function InputLabelSpan({spanText}) {
    return (
        <span className='input-span'>
            {spanText}
        </span>
    );
}

export default InputLabelSpan;
