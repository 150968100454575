import './footerGeo.css';
import ContactLine from '../contactLine/contactLine';

function FooterGeo() {
    return (
        <ul className='footer__geo'>
            <ContactLine contactLineContent={'geo'} geoStreet={18}/>
            <ContactLine contactLineContent={'geo'} geoStreet={207}/>
            <ContactLine contactLineContent={'geo'} geoStreet={28}/>
        </ul>
    );
}

export default FooterGeo;
