import {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './formPhone.css';

function FormPhone() {

    const [phone, setPhone] = useState();

    return (
        <PhoneInput
            country={'pl'}
            value={phone}
            onChange={phone => setPhone(phone)}
            disableDropdown
            countryCodeEditable={false}
            inputProps={{
                name: 'Tel',
                required: true,
                autoFocus: true,
            }}
        />
      );
}

export default FormPhone;
