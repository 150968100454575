import './button.css';

function Button({isFilled, isSubmit, whatSection, buttonText, setIsOpen}) {

    return (
        <input
            type={`${isSubmit ? 'submit' : 'button'}`}
            className={`${whatSection}__button button ${isFilled ? `` : `button_white`}`}
            value={buttonText}
            onClick={() => {
                whatSection !== 'form' ? setIsOpen(true) : console.log('');
            }}
        />
    );
}

export default Button;
