import './stepsItem.css';
import Button from '../button/button';
import stepFirst from '../../img/icons/ic_first-step.svg';
import stepSecond from '../../img/icons/ic_second-step.svg';
import stepThird from '../../img/icons/ic_third-step.svg';
import clouds from '../../img/blue-clouds.png';
import pizzaBear from '../../img/pizza-bear.png';
import pepperoniKids from '../../img/pepperoni-kids.png';

function StepsItem({whatStep, setIsOpen}) {

    let stepIcon =
        whatStep === 'first-step' ? stepFirst :
            whatStep === 'second-step' ? stepSecond :
                whatStep === 'third-step' ? stepThird :
                    null
    ;

    let stepImg =
        whatStep === 'first-step' ? clouds :
            whatStep === 'second-step' ? pizzaBear :
                whatStep === 'third-step' ? pepperoniKids :
                    null
    ;

    let stepText =
        whatStep === 'first-step' ? <span>Wybierz odpowiednią datę<br/> i zacznij przygodę!</span> :
            whatStep === 'second-step' ? <span>Wybierz swoją pizzę<br/> już teraz!!!</span> :
                whatStep === 'third-step' ? <span>Dzielcie te bezcenne<br/> chwile razem.</span> :
                    null
    ;


    return (
        <div
            className={`what-interesting__steps-item ${whatStep === 'second-step' ? 'what-interesting__steps-item_reverse' : ''}`}>
            <img className={`what-interesting__${whatStep}_icon step-icon`} src={stepIcon} alt={`${whatStep}_icon`}/>
            {stepText}
            <img className={`what-interesting__${whatStep}_image`} src={stepImg} alt={`${whatStep}_image`}/>
            {
                whatStep === 'first-step' ?
                    <Button
                        isFilled={true}
                        whatSection={'what-interesting-step'}
                        buttonText={'Zapisz się'}
                        setIsOpen={setIsOpen}
                    />
                    : null
            }
        </div>
    );
}

export default StepsItem;
