import { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './formTimepicker.css';

function FormTimepicker({placeholder, name}) {

    const [startTime, setStartTime] = useState();
    const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className='timepicker-input'
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            placeholder={placeholder}
            name={name}
            autoComplete='off'
            required
        ></input>
    ));

    const filterPassedTime = (time) => {
        const startTime = new Date().setHours(8, 30);
        const endTime = new Date(time);

        return endTime > startTime;
    };

    return (
        <DatePicker
            selected={startTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            excludeTimes={[
                new Date().setHours(20, 30),
                new Date().setHours(21, 0),
                new Date().setHours(21, 30),
                new Date().setHours(22, 0),
                new Date().setHours(22, 30),
                new Date().setHours(23, 0),
                new Date().setHours(23, 30),
            ]}
            dateFormat='HH:mm'
            timeFormat='HH:mm'
            is24Hour
            filterTime={filterPassedTime}
            onChange={(time) => setStartTime(time)}
            customInput={<ExampleCustomInput />}
            dayClassName={() => 'timepicker-day-class'}
            popperClassName='timepicker-class'
        />
    );
}

export default FormTimepicker;
