import React, {Component} from 'react';
import Slider from 'react-slick';
import './dodoSlider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrow from '../../img/icons/ic_arrow-right.svg';
import slider_1 from '../../img/slider/slider_first.png';
import slider_2 from '../../img/slider/slider_second.png';
import slider_3 from '../../img/slider/slider_third.png';

function PrevArrow(props) {
    const {onClick} = props;
    return (
        <img
            src={arrow}
            alt={'prev'}
            className={'arrow_prev'}
            onClick={onClick}
        />
    );
}

function NextArrow(props) {
    const {onClick} = props;
    return (
        <img
            src={arrow}
            alt={'next'}
            className={'arrow_next'}
            onClick={onClick}
        />
    );
}

export default class DodoSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>
        };
        return (
            <div className={'dodo-slider'}>
                <Slider {...settings}>
                    <div>
                        <img src={slider_1} alt={'image_1'}/>
                    </div>
                    <div>
                        <img src={slider_2} alt={'image_2'}/>
                    </div>
                    <div>
                        <img src={slider_3} alt={'image_3'}/>
                    </div>
                </Slider>
            </div>
        );
    }
}
