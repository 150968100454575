import './mediaItem.css';
import DodoSlider from '../dodoSlider/dodoSlider';
import LiveItem from '../liveItem/liveItem';
import blue_some from '../../img/icons/ic_bluesome.svg';

function MediaItem({whatSection}) {
    return (
        <div className={`${whatSection}__media-item media-item`}>
            {
                whatSection === 'lessons-slider' ?
                    <>
                        <DodoSlider/>
                        <img className={'blue-some'} src={blue_some} alt={'blue'}/>
                    </> :
                    whatSection === 'dodo-live' ?
                        <LiveItem/> :
                        null
            }
        </div>
    );
}

export default MediaItem;
