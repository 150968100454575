import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Page from './page';

const root = ReactDOM.createRoot(document.getElementById('page'));
root.render(
    <React.StrictMode>
        <Page/>
    </React.StrictMode>
);
