import './formAgreement.css';
import FormCheckbox from '../formCheckbox/formCheckbox';

function FormAgreement() {
    return (
        <li className='form-agreement'>
            <span>Prosimy o wyrażenie zgody <b>*</b></span>
            <div>
                <FormCheckbox/>
                <p>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym
                    formularzu w celu umówienia się na warsztaty zorganizowane przez Dodo Pizza Poland
                    Sp. z o. o. z siedzibą w Warszawie 00-095, plac Bankowy 2.
                </p>
            </div>
        </li>
    );
}

export default FormAgreement;
