import './formRadio.css';

function FormRadio({labelRadioText, required}) {
    return (
        <label className='radio'>
            <span className='radio-label'>{labelRadioText}</span>
            <input type='radio' name='Place' value={labelRadioText} required={required ? 'required' : false} />
            <span className='radio-body'></span>
        </label>
    );
}

export default FormRadio;
