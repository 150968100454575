import './formChooseAddress.css';
import InputLabelSpan from '../inputLabelSpan/inputLabelSpan';
import FormRadio from '../formRadio/formRadio';

function FormChooseAddress() {
    return (
        <li className='radio_address'>
            <InputLabelSpan spanText={'Prosimy wybrać pizzerie'}/>
            <div>
                <FormRadio labelRadioText={'Aleja Jana Pawła II, 18 | Rondo ONZ'} required={true}/>
                <FormRadio labelRadioText={'Ulica Grochowska, 207 | Rondo Wiatraczna'}/>
                <FormRadio labelRadioText={'Ulica Domaniewska, 28 | Wilanowska'}/>
            </div>
        </li>
    );
}

export default FormChooseAddress;
