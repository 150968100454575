import './lessonsSlider.css';
import Title from '../title/title';
import MediaItem from '../mediaItem/mediaItem';

function LessonsSlider({whatSection}) {
    return (
        <section className='lessons-slider'>
            <div className='container'>
                <div className='lessons-slider__row'>
                    <Title
                        whatSection={whatSection}
                        isBigTitle={false}
                    />
                    <MediaItem
                        whatSection={whatSection}
                    />
                </div>
            </div>
        </section>
    );
}

export default LessonsSlider;
