import './littleInfoLine.css';
import LittleInfoItem from '../littleInfoItem/littleInfoItem';

function LittleInfoLine() {
    return (
        <div className='what-interesting__info-line'>
            <LittleInfoItem infoText={'Zajęcia będą trwać'} infoValue={'~25 minut'}/>
            <LittleInfoItem infoText={'Wiek uczestników'} infoValue={'4-99 lat'}/>
        </div>
    );
}

export default LittleInfoLine;
