import {useState} from 'react';
import './page.css';
import Banner from './components/banner/banner';
import WhatInteresting from './components/whatInteresting/whatInteresting';
import LessonsSlider from './components/lessonsSlider/lessonsSlider';
import DodoLive from './components/dodoLive/dodoLive';
import Footer from './components/footer/footer';
import Copyright from './components/copyright/copyright';
import Form from './components/form/form';

function Page() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`wrapper ${isOpen ? 'wrapper_open-popup' : ''}`}>
            <Banner whatSection={'banner'} setIsOpen={setIsOpen}/>
            <WhatInteresting whatSection={'what-interesting'} setIsOpen={setIsOpen}/>
            <LessonsSlider whatSection={'lessons-slider'}/>
            <DodoLive whatSection={'dodo-live'}/>
            <Footer whatSection={'footer'} setIsOpen={setIsOpen}/>
            <Copyright whatSection={'copy'}/>
            <Form whatSection={'form'} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </div>
    );
}

export default Page;
