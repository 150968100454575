import './title.css';

function Title({isMainTitle, isBigTitle, whatSection}) {
    return (
        <>
            {
                isMainTitle ?
                    <h1 className={`${whatSection}__title title`}>
                        ODKRYJ<br/> ŚWIAT PIZZY<br/> Z
                        <span> DODO</span>
                    </h1>
                    :
                    <h2 className={`${whatSection}__title title ${isBigTitle ? `` : `title_small`}`}>
                        {whatSection === 'what-interesting' ?
                            <>
                                <span>WOW! </span>
                                CO JEST TAKIEGO CIEKAWEGO W DODO?<br/> DODO POMOŻE CI TO ODKRYĆ!
                            </>
                            : whatSection === 'lessons-slider' ?
                                <>
                                    ZAJĘCIA SĄ
                                    <span> NIE TYLKO<br/> ZABAWNE, ALE RÓWNIEŻ<br/> POŻYTECZNE</span>
                                </>
                                : whatSection === 'dodo-live' ?
                                <>
                                    WYCIECZKA<br/> DO
                                    <span> PIZZERII</span>
                                </>
                                    :
                                    <>
                                        <span>ZAPISY</span>
                                    </>
                        }
                    </h2>
            }
        </>
    );
}

export default Title;
