import './footerContacts.css'
import ContactLine from '../contactLine/contactLine';
import Link from '../link/link';
import QrCode from '../qrCode/qrCode';

function FooterContacts({whatSection}) {
    return (
        <div className='footer__contacts'>
            <ContactLine contactLineContent={'phone'}/>
            <Link
                whatSection={whatSection}
                linkUrl={'https://www.dodopizza.pl/'}
                linkValue={'dodopizza.pl'}
            />
            <QrCode/>
        </div>
    );
}

export default FooterContacts;
