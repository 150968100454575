import './subtitle.css';

function Subtitle({whatSection, hasBigLineHeight}) {

    let subtitle =
        whatSection === 'banner' ?
            <>
                <span>Warsztaty w cenie pizzy.</span><br/>
                Nauczymy, jak własnoręcznie<br/> przygotować smaczną pizzę.<br/> Przyjdź do nas, zamień się<br/> w super
                szefa kuchni!
            </> :
            whatSection === 'what-interesting' ?
                <>
                    Na zakończenie zajęć dzieci<br/> otrzymają dyplom uczestnictwa.
                </> :
                whatSection === 'dodo-live' ?
                    <>
                        Niczego nie ukrywamy.<br/> Zawsze można przyjść do nas<br/> i zobaczyć jak robimy pizzę.
                    </> :
                    <>
                        <span>Zostaw swoje dane i wybierz dogodny termin.</span>
                    </>

    return (
        <p className={`${whatSection}__subtitle subtitle`} style={hasBigLineHeight ? {lineHeight: '140%'} : null}>
            {subtitle}
        </p>
    );
}

export default Subtitle;
