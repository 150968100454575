import './copyright.css';
import Logo from '../logo/logo';
import Link from "../link/link";

function Copyright() {
    return (
        <div className='copyright'>
            <div className='container'>
                <div className='copyright__row'>
                    <div className='copyright__atr'>
                        <Logo whichLine={'copy'}/>
                        <span>© 2023</span>
                    </div>
                    <div className='copyright__privacy'>
                        <Link
                            whatSection={'copy'}
                            linkUrl={'https://drive.google.com/file/d/1tr9kR0D2Z_0iNd2vestHpbOA0lUCaX-r/view?usp=share_link'}
                            linkValue={'Regulamin organizacji warsztatów'}
                        />
                        <Link
                            whatSection={'copy'}
                            linkUrl={'https://drive.google.com/file/d/1TqW4HY4C_4cklmO_lJSFusS1cFApROub/view?usp=share_link'}
                            linkValue={'Polityka prywatności'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Copyright;
