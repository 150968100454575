import './formLabel.css';
import InputLabelSpan from '../inputLabelSpan/inputLabelSpan';
import FormPhone from '../formPhone/formPhone';
import FormDatepicker from '../formDatepicker/formDatepicker';
import FormTimepicker from '../formTimepicker/formTimepicker';
import FormPeopleCounter from '../formPeopleCounter/formPeopleCounter';

function FormLabel({labelText, placeholder, name, type}) {
    return (
        type === 'tel' ?
            <li>
                <label className='label label_phone'>
                    <InputLabelSpan spanText={labelText}/>
                    <FormPhone placeholder={'+48589568458'} name={name} />
                </label>
            </li> :
        type === 'date' ?
            <li>
                <label className='label label_date'>
                    <InputLabelSpan spanText={labelText}/>
                    <FormDatepicker placeholder={placeholder} name={name}/>
                </label>
            </li> :
            type === 'time' ?
                <li>
                    <label className='label label_date'>
                        <InputLabelSpan spanText={labelText}/>
                        <FormTimepicker placeholder={placeholder} name={name}/>
                    </label>
                </li> :
                name === 'Val' ?
                    <li>
                        <label className='label label_people'>
                            <InputLabelSpan spanText={labelText}/>
                            <FormPeopleCounter placeholder={placeholder} name={name}/>
                        </label>
                    </li>
                    :
                    <li>
                        <label className='label'>
                            <InputLabelSpan spanText={labelText}/>
                            <input placeholder={placeholder} name={name} type={type} autoComplete='off' required/>
                        </label>
                    </li>
    );
}

export default FormLabel;
