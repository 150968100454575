import './dodoLive.css';
import BubbleBorder from '../bubbleBorder/bubbleBorder';
import Title from '../title/title';
import Subtitle from "../subtitle/subtitle";
import MediaItem from "../mediaItem/mediaItem";
import Link from '../link/link';

function DodoLive({whatSection}) {
    return (
        <section className='dodo-live'>
            <BubbleBorder isReversed={false}/>
            <div className='container'>
                <div className='dodo-live__row'>
                    <div className='dodo-live__info'>
                        <Title
                            whatSection={whatSection}
                            isBigTitle={true}
                            titleText={'WYCIECZKA DO '}
                            titleOrangeText={'PIZZERII'}
                        />
                        <Subtitle
                            whatSection={whatSection}
                        />
                    </div>
                    <div className={'dodo-live__media'}>
                        <MediaItem
                            whatSection={whatSection}
                        />
                        <span>
                              Oglądaj kamery na żywo&nbsp;
                            <Link
                                whatSection={whatSection}
                                linkUrl={'https://www.dodopizza.pl/'}
                                linkValue={'dodopizza.pl'}
                            />
                          </span>
                    </div>
                </div>
            </div>
            <BubbleBorder isReversed/>
        </section>
    );
}

export default DodoLive;
