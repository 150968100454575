import liveImage from '../../img/dodo-live_image.png';

function LiveItem() {
    return (
        <>
            <a href={'https://www.dodopizza.pl/'} target='_blank'>
                <img src={liveImage} alt={'dodo-live'}/>
            </a>
        </>
    )
}

export default LiveItem;