import './footer.css';
import Button from '../button/button';
import FooterGeo from '../footerGeo/footerGeo';
import FooterContacts from '../footerContacts/footerContacts';

function Footer({whatSection, setIsOpen}) {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer__row'>
                    <Button
                        isFilled={false}
                        whatSection={whatSection}
                        buttonText={'Zapisz się JUŻ TERAZ!'}
                        setIsOpen={setIsOpen}
                    />
                    <div className='footer__info'>
                        <FooterGeo/>
                        <FooterContacts whatSection={whatSection}/>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
