import './littleInfoItem.css';

function LittleInfoItem({infoText, infoValue}) {
    return (
        <div className='what-interesting__little-info'>
            {infoText}
            <span>{infoValue}</span>
        </div>
    );
}

export default LittleInfoItem;
