import './formCheckbox.css';

function FormCheckbox() {
    return (
        <label className='checkbox'>
            <input type='checkbox' name='Check' required='required' />
            <span className='checkbox-body'></span>
        </label>
    );
}

export default FormCheckbox;
