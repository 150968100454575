import {useState} from 'react';
import './formPeopleCounter.css';

function FormPeopleCounter({placeholder, name}) {

    const [peopleValue, setPeopleValue] = useState(1);

    return (
        <div className='people-counter'>
            <span onClick={() => {setPeopleValue( +peopleValue - 1)}}>-</span>
            <input
                type='number'
                value={peopleValue}
                className='people-input'
                placeholder={placeholder}
                name={name}
                onInput={(e) => {setPeopleValue(e.currentTarget.value)}}
                autoComplete='off'
                required
            ></input>
            <span onClick={() => {setPeopleValue(+peopleValue + 1)}}>+</span>
        </div>
      );
}

export default FormPeopleCounter;
