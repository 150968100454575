import './link.css';

function Link({whatSection, linkUrl, linkValue}) {
    return (
        <a
            href={linkUrl}
            className={`${whatSection}__link link`}
            target='_blank'
        >
            {linkValue}
        </a>
    );
}

export default Link;
