import './banner.css';
import Logo from '../logo/logo';
import Button from '../button/button';
import Title from '../title/title';
import Subtitle from '../subtitle/subtitle';

function Banner({whatSection, setIsOpen}) {
    return (
        <section className='banner'>
            <div className='container'>
                <div className='banner__row'>
                    <Logo whichLine={'banner'}/>
                    <Title
                        isMainTitle
                        whatSection={whatSection}
                    />
                    <Subtitle
                        whatSection={whatSection}
                    />
                    <Button
                        isFilled={true}
                        whatSection={whatSection}
                        buttonText={'Zapisz się NA WARSZTAT'}
                        setIsOpen={setIsOpen}
                    />
                </div>
            </div>
        </section>
    );
}

export default Banner;
