import './contactLine.css';
import location from '../../img/icons/ic_geolocation.svg';
import phone from '../../img/icons/ic_phone.svg';

function ContactLine({contactLineContent, geoStreet}) {

    let geoText =
        geoStreet === 18 ?
            <span>Aleja Jana Pawła II, 18<br/> Rondo ONZ</span> :
            geoStreet === 207 ?
                <span>Ulica Grochowska, 207<br/> Rondo Wiatraczna</span> :
                geoStreet === 28 ?
                    <span>Ulica Domaniewska, 28<br/> Wilanowska</span> :
                    null
    ;

    return (
        contactLineContent === 'geo' ?
            <li className={'footer__location contact-line'}>
                <img src={location} alt={'geo'}/>
                {geoText}
            </li>
            :
            contactLineContent === 'phone' ?
                <div className={'footer__phone contact-line'}>
                    <img src={phone} alt={'phone'}/>
                    <a href={'tel:+48221530000'}>221530000</a>
                </div>
                : null
    );
}

export default ContactLine;
