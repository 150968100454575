import './whatInteresting.css';
import BubbleBorder from '../bubbleBorder/bubbleBorder';
import Title from '../title/title';
import StepsLine from '../stepsLine/stepsLine';
import LittleInfoLine from '../littleInfoLine/littleInfoLine';
import Subtitle from '../subtitle/subtitle';
import Button from '../button/button';

function WhatInteresting({whatSection, setIsOpen}) {
    return (
        <section className='what-interesting'>
            <BubbleBorder isReversed={false}/>
            <div className='container'>
                <div className='what-interesting__row'>
                    <Title
                        whatSection={whatSection}
                        isBigTitle={false}
                    />
                    <StepsLine setIsOpen={setIsOpen} />
                    <LittleInfoLine/>
                    <Subtitle
                        whatSection={whatSection}
                        hasBigLineHeight
                    />
                    <Button
                        isFilled={true}
                        whatSection={whatSection}
                        buttonText={'Zapisz się JUŻ TERAZ!'}
                        setIsOpen={setIsOpen}
                    />
                </div>
            </div>
            <BubbleBorder isReversed/>
        </section>
    );
}

export default WhatInteresting;
