import './stepsLine.css';
import StepsItem from '../stepsItem/stepsItem';

function StepsLine({setIsOpen}) {
    return (
        <div className='what-interesting__steps-line'>
            <StepsItem
                whatStep={'first-step'}
                stepText={'Wybierz odpowiednią datę i zacznij przygodę!'}
                setIsOpen={setIsOpen}
            />
            <StepsItem
                whatStep={'second-step'}
                stepText={'Wybierz swoją pizzę już teraz!!!'}
            />
            <StepsItem
                whatStep={'third-step'}
                stepText={'Dzielcie te bezcenne chwile razem.'}
            />
        </div>
    );
}

export default StepsLine;
