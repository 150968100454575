import {useState} from 'react';
import './form.css';
import FormTitleLine from '../formTitleLine/formTitleLine';
import Subtitle from '../subtitle/subtitle';
import FormLabel from '../formLabel/formLabel';
import FormChooseAddress from '../formChooseAddress/formChooseAddress';
import FormAgreement from '../formAgreement/formAgreement';
import Button from '../button/button';

function Form({isOpen, setIsOpen}) {

    const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

    function SentData(e) {
        const formElements = document.querySelector("form");
        const formDataDodo = new FormData(formElements);
        fetch('https://script.google.com/macros/s/AKfycby3gz27r3ci-_q2YDoM2ZiCmR4TmGkXRTfWpUE0qfFfd4ZMsvFaGWnUKOFEIPTYS3h3/exec', {
            method: 'POST',
            body: formDataDodo,
        }).then(() => setOpenConfirmPopup(true))
            .then(() => {
                setTimeout(() => {
                    setOpenConfirmPopup(false);
                    setIsOpen(false)
                }, 2800);
            })
            .catch((error) => {
                console.log(error);
            });
        e.preventDefault();
        e.currentTarget.reset();
    }

    function closePopup(e) {
        if (e.target === e.currentTarget) {
            setIsOpen(false);
        }
    }

    return (
        <div className='background-form' style={{display: `${isOpen ? 'block' : 'none'}`}} onClick={(e) => {
            closePopup(e)
        }}>
            <form className='form' onSubmit={(e) => {
                SentData(e);
            }}>
                <FormTitleLine setIsOpen={setIsOpen}/>
                <Subtitle whatSection={'form'}/>
                <ul>
                    <FormLabel
                        labelText={'Imię i nazwisko'}
                        placeholder={'Katarzyna Lis'}
                        name={'Name'}
                        type={'text'}
                    />
                    <FormLabel
                        labelText={'Wiek uczestnika/ów'}
                        placeholder={'12'}
                        name={'Age'}
                        type={'number'}
                    />
                    <FormLabel
                        labelText={'Liczba uczestników'}
                        placeholder={'1'}
                        name={'Val'}
                        type={'number'}
                    />
                    <FormLabel
                        labelText={'Numer telefonu'}
                        placeholder={'+48567845657'}
                        name={'Tel'}
                        type={'tel'}
                    />
                    <FormChooseAddress/>
                    <FormLabel
                        labelText={'Dzień'}
                        placeholder={'01-08-2023'}
                        name={'Date'}
                        type={'date'}
                    />
                    <FormLabel
                        labelText={'Godzina'}
                        placeholder={'16:00'}
                        name={'Time'}
                        type={'time'}
                    />
                    <FormAgreement/>
                    <li>
                        <Button isSubmit isFilled whatSection={'form'} buttonText='Zapisz się'/>
                    </li>
                </ul>
                <div className={`form__confirm-popup ${openConfirmPopup ? 'form__confirm-popup_show' : ''}`}>
                    <div className='form-popup'>
                        Wniosek przesłany pomyślnie!
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Form;
